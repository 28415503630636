<template>
  <div class="iphone-mask" @click.self="destroy" v-if="value">
    <div class="iphone">
      <div class="screen">

        <!--   状态栏     -->
        <div class="danger-zoom">
          <span class="time">{{ `${new Date().getHours()}:${new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()}` }}</span>
          <img :src="require('./iphone-status.jpg')" draggable="false">
        </div>

        <!--   插槽     -->
        <div style="" class="iphone-content">
          <slot>
            <h1>暂无数据</h1>
          </slot>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import Carousel from '@/components/iphonePreview/carousel'
/**
 * @author  XuHongli
 * @date  2022/8/25 13:57
 * @version 1.0
 * @description iphone预览组件
 */
export default {
  name: 'IphonePreview',
  components: { Carousel },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    wheel: { // 禁止滚轮时间
      type: Boolean,
      default: false
    },
    scroll: { // 隐藏背后滚动条
      type: Boolean,
      default: false
    },
    appendBody: { // 挂载到Body
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      Listener: undefined
    }
  },
  beforeUpdate() {
    this.$nextTick(() => {
      if (this.appendBody) {
        document.querySelector('body').append(this.$el)
      }

      if (this.wheel && this.value) {
        this.stopWheel()
      }

      if (this.scroll && this.value) {
        this.stopScroll()
      }
    })
  },
  methods: {
    /**
     * 销毁对象
     */
    destroy() {
      console.log('销毁Iphone预览')
      // 恢复滚动条
      document.body.style.height = 'unset'
      document.body.style.overflow = 'unset'
      // 移除监听
      window.removeEventListener('wheel', this.Listener || null)
      this.$emit('input', false)
    },
    /**
     * 禁止滚动事件
     */
    stopWheel() {
      this.Listener = (e) => {
        e.preventDefault()
      }
      window.addEventListener('wheel', this.Listener, {
        passive: false
      })
    },
    /**
     * 隐藏页面滚动条
     */
    stopScroll() {
      document.body.style.height = '100%'
      document.body.style.overflow = 'hidden'
    }
  },

}
</script>

<style scoped lang="scss">
.iphone-mask {
  z-index: 10000;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  .iphone{
    width: 340px;
    height: 690px;
    display: flex;
    justify-content: center;
    align-items: center;
    >img{

      clip-path: revert;
      position: absolute;
    }
  }
}
</style>
<style scoped lang="scss">
.screen{
  -webkit-border-image:url('./iphone.png') 0 0 stretch;
  //border-image:url('./iphone.png') 0 0 stretch;
  width: 93%;
  height: 100%;
  background-color: rgb(245,245,245);
  background-clip: content-box;
  border-radius: 50px;
  padding: 18px 18px;
  font-family: Helvetica,serif;
  .danger-zoom{
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >img{
      width: 62px;
      margin-right: 18px;
      max-width: 100%;
    }
    .time{
      margin-left: 25px;
      margin-top: 3px;
      font-weight: 600;
      font-size: 14px;
      color: #111;
    }
  }
}
.iphone-content{
  overflow-y: auto;
  height: 624px;
  border-bottom-left-radius: 33px;
  border-bottom-right-radius: 33px;
  &::-webkit-scrollbar {
    width: 0;
  }
}
</style>
<!-- 内容样式  -->
<style lang="scss">
.previewContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  img{
    max-width: 100%;
    width: 100% !important;
    height: auto;
    max-height: 100%;
  }
  br {
    height: 0;
  }
}
</style>
